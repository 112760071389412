<template>
  <el-table
    v-horizontal-scroll="scrollType"
    class="white-bg radius-table no-golbal-table noborder-resize"
    :class="className"
    ref="myTable"
    v-loading="loading"
    :stripe="false"
    :key="`tableKey${tableKey}`"
    :row-key="getRowKey"
    :data="tableData"
    :height="height"
    v-bind="$attrs"
    v-on="$listeners"
    :border="border"
    :default-sort="defaultSort"
    @header-dragend="headerDragend"
    @header-click="headerClick"
    @sort-change="handleSort"
    @scroll="handleScroll">
    <div slot="empty" class="table-empty">{{ $t('title712') }}</div>
    <template v-for="(column, index) in defaultCol">
      <el-table-column :key="index+'v'" v-if="column.type && column.type.includes('selection')" :reserve-selection="column.reserveSelection" type="selection" :width="column.width" />
      <template v-else>
        <el-table-column
          v-if="column.children && column.children.length"
          :key="index"
          :prop="column.prop"
          :label="column.label"
          :width="column.width"
          :sortable="column.sortable"
          :fixed="column.fixed"
          :align="column.align"
          :min-width="column.minWidth"
          :label-class-name="column.labelClass"
        >
          <template v-if="column.content" slot="header">
            <span>{{ column.label }}</span>
            <el-tooltip placement="top">
              <div slot="content" v-html="column.content"></div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template v-for="(child, j) in column.children">
            <el-table-column
              :key="`child${j}`"
              :prop="child.prop"
              :label="child.label"
              :width="child.width"
              :sortable="child.sortable"
              :align="child.align"
              :min-width="child.minWidth"
              :label-class-name="child.labelClass"
              :show-overflow-tooltip="column.overflow"
            >
              <template v-if="child.content" slot="header">
                <span>{{ child.label }}</span>
                <el-tooltip placement="top">
                  <div slot="content" v-html="child.content"></div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <template v-if="!child.render">
                  <span v-if="child.toFixed">
                    {{Number(scope.row[child.prop] || scope.row[child.prop] === 0 ? scope.row[child.prop] : (child.defaultValue || '0')).toFixed(2)}}
                  </span>
                  <span v-else>
                    {{scope.row[child.prop] || scope.row[child.prop] === 0 ? scope.row[child.prop] : (child.defaultValue || '-')}}
                  </span>
                </template>
                <!-- render -->
                <template v-else>
                  <RenderDom :row="scope.row" :row-index="scope.$index" :index="index" :render="child.render" :column="child" />
                </template>
              </template>
            </el-table-column>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="index"
          :prop="column.prop"
          :label="column.label"
          :width="column.width"
          :sortable="column.sortable"
          :fixed="column.fixed"
          :align="column.align"
          :min-width="column.minWidth"
          :label-class-name="column.labelClass || column.labelClassName"
          :class-name="column.className"
          :resizable="!!column.labelClassName"
          :show-overflow-tooltip="column.overflow"
        >
          <template v-if="column.headerRander" slot="header">
            <RenderDom :index="index" :render="column.headerRander" :column="column" />
          </template>
          <template v-if="column.content" slot="header">
            <span>{{ column.label }}</span>
            <el-tooltip placement="top">
              <div slot="content" v-html="column.content"></div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <template v-if="!column.render">
              <span v-if="column.toFixed">
                {{Number(scope.row[column.prop] || scope.row[column.prop] === 0 ? scope.row[column.prop] : (column.defaultValue || '0')).toFixed(2)}}
              </span>
              <span v-else>
                {{scope.row[column.prop] || scope.row[column.prop] === 0 ? scope.row[column.prop] : (column.defaultValue || '-')}}
              </span>
            </template>
            <!-- render -->
            <template v-else>
              <RenderDom :row="scope.row" :row-index="scope.$index" :index="index" :render="column.render" :column="column" />
            </template>
            <!-- slot 你可以其他常用项 -->
          </template>
        </el-table-column>
      </template>
    </template>
  </el-table>
</template>
<script>
import tableHeaderDragend from '@/mixins/tableHeaderDragend'
import tableEcharts from '@/views/erp/goodsAnalysis/components/charts.vue'
import lineEcharts from '@/views/bi/financialData/goodsProfit/components/goodsAnalysis/charts.vue'
import moreInfo from '@/views/bi/financialData/goodsAnalyse/components/goodsAnalysis/moreInfo.vue';
export default {
  props: {
    loading: Boolean,
    lazy: {
      type: Boolean,
      default: false
    },
    tableKey: [String, Number],
    tableData: {
      type: Array,
      default: () => []
    },
    defaultCol: {
      type: Array,
      default: () => []
    },
    defaultSort: {
      type: Object,
      default: () => ({})
    },
    height: {
      type:String,
      default: '100%'
    },
    border: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    scrollType: {
      type: String,
      default: 'hidden'
    }
  },
  mixins: [tableHeaderDragend],
  components: {
    tableEcharts,
    lineEcharts,
    moreInfo,
    RenderDom: {
      name: 'renderTable',
      functional: true, // 函数式组件 - 无 data 和 this 上下文 => better render
      props: {
        row: Object || {}, // 单行数据
        index: Number, // 列的index
        rowIndex: Number,
        render: Function,
        column: {
          type: Object,
          default: null
        }
      },
      /**
       * @param {Function} createElement - 原生创建dom元素的方法， 弃用，推荐使用 jsx
       * @param {Object} ctx - 渲染的节点的this对象
       * @argument 传递参数 row index
       */
      render (createElement, ctx) {
        const { row, rowIndex, index } = ctx.props
        return ctx.props.render(createElement, row, index, rowIndex)
      }
    }
  },
  watch:{
    // loading(val){
    //   if(!val){
    //     this.totalData = this.tableData.map((item,index) => {
    //       return {
    //         ...item,
    //         table_id: 'tableKey'+ index
    //       }
    //     })
    //     this.tableDatas = []
    //     this.resetScrollTop()
    //     if(this.lazy){
    //       this.currentChunk = 0
    //       this.loadMoreData();
    //     }else{
    //       this.tableDatas = this.totalData
    //     }
    //   }
    // }
  },
  data () {
    return {
      tableDatas: [],
      totalData: [], // 所有数据
      chunkSize: 20, // 每次加载的数据量
      currentChunk: 0 // 当前加载的块
    }
  },
  created() {
  },
  mounted() {
    if(this.lazy){
      this.$nextTick(() => {
        const tableBodyWrapper = this.$refs.myTable.$el.querySelector('.el-table__body-wrapper');
        if (tableBodyWrapper) {
          tableBodyWrapper.addEventListener('scroll', this.handleScroll);
        }
      });
    }
  },
  beforeDestroy() {
    if(this.lazy){
      const tableBodyWrapper = this.$refs.myTable.$el.querySelector('.el-table__body-wrapper');
      if (tableBodyWrapper) {
        tableBodyWrapper.removeEventListener('scroll', this.handleScroll);
      }
    }
  },
  methods: {
    resetScrollTop() {
      this.$nextTick(() => {
        const tableBodyWrapper = this.$refs.myTable.$el.querySelector('.el-table__body-wrapper');
        if (tableBodyWrapper) {
          tableBodyWrapper.scrollTop = 0;
        }
      });
    },
    handleScroll(event){
      const { target } = event;
      const bottomReached = target.scrollHeight - target.scrollTop == target.clientHeight;
      if (bottomReached && this.tableDatas.length < this.totalData.length) {
        this.loadMoreData();
      }
    },
    loadMoreData() {
      const start = this.currentChunk * this.chunkSize;
      const end = start + this.chunkSize;
      this.tableDatas = [...this.tableDatas, ...this.totalData.slice(start, end)];
      this.currentChunk += 1;
    },
    handleSort(sortParams) {
      if (!(this.defaultSort && this.defaultSort.prop)) return
      this.$emit('handleSort', sortParams)
    },
    clearSort() {
      this.$refs.myTable.clearSort()
    },
    getRowKey(row,index){
      return row.table_id
    },
    clearSelection() {
      this.$refs.myTable.clearSelection()
    }
  }
}
</script>
<style>
.no-golbal-table .el-table__cell {
  border-right: none
}
.no-golbal-table .el-table--group, .el-table--border {
  border: none
}
.no-golbal-table .is-group tr:last-child th {
  border-top: 1px solid #D3D3D3 !important;
}
.no-golbal-table .is-group tr:last-child th:first-child {
  border-radius: 0
}
.el-table th.el-table__cell > .cell{
  color: #666666;
}
.table-empty{
  padding-top: 105px;
  background:url('../../assets/images/empty.png') no-repeat top 50px center;
  background-size: 100px;
  color:#999;
}
</style>
