const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  userId: state => state.user.id,
  isGroup: state => state.user.isGroup,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  nickname: state => state.user.nickname,
  introduction: state => state.user.introduction,
  myPackage: state => state.user.myPackage,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
  permission_routes: state => state.permission.routes,
  // 工具栏
  topbarRouters:state => state.permission.topbarRouters,
  defaultRoutes:state => state.permission.defaultRoutes,
  sidebarRouters:state => state.permission.sidebarRouters,
  routeMenuPriority:state => state.permission.routeMenuPriority,
  // 数据字典
  dict_datas: state => state.dict.dictDatas,
  notice: state => state.notice.notice,
  showHelp: state => state.user.showHelp,
  currency: state => state.user.currency,
  currencySymbol: state => state.user.currencySymbol,
  initialState: state => state.user.initialState,
  isShopAdAuth: state => state.user.isShopAdAuth,
  // crm配置
  kolConfig: state => state.crm.kolConfig,
  kolAuth: state => state.crm.kolAuth,
  explain:state => state.notice.explain,
}
export default getters
