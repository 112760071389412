var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    { attrs: { width: "340", "visible-arrow": false, trigger: "click" } },
    [
      _c(
        "div",
        {
          staticClass: "flex-col",
          staticStyle: {
            padding: "8px",
            "max-height": "680px",
            overflow: "hidden auto",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-row",
              staticStyle: {
                width: "100%",
                "border-bottom": "1px solid #F6F7FB",
                "padding-bottom": "20px",
              },
            },
            [
              _vm.row.imageUrl
                ? _c("el-image", {
                    directives: [
                      {
                        name: "preview",
                        rawName: "v-preview",
                        value: _vm.row.imageUrl,
                        expression: "row.imageUrl",
                      },
                    ],
                    staticStyle: {
                      width: "32px",
                      height: "32px",
                      "min-width": "32px",
                      "margin-right": "10px",
                    },
                    attrs: { src: _vm.row.imageUrl },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "flex-col",
                  staticStyle: {
                    width: "100%",
                    height: "32px",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex-row",
                      staticStyle: {
                        width: "100%",
                        "justify-content": "space-between",
                        "align-items": "center",
                        "margin-top": "-2px",
                      },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.row.name,
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "ellipsis",
                              staticStyle: { "max-width": "192px" },
                            },
                            [_vm._v(_vm._s(_vm.row.name))]
                          ),
                        ]
                      ),
                      _vm.row.rating
                        ? _c("span", { staticStyle: { color: "#FD4C2B" } }, [
                            _vm._v(_vm._s(Number(_vm.row.rating).toFixed(1))),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.row.sku
                    ? _c("span", [
                        _c("span", { staticStyle: { color: "#999" } }, [
                          _vm._v(_vm._s(_vm.row.sku || "-")),
                        ]),
                        _c("i", {
                          staticClass: "el-icon-document-copy",
                          staticStyle: {
                            "margin-right": "10px",
                            "margin-left": "4px",
                            cursor: "pointer",
                            color: "#1377FF",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.navigatorCopy(_vm.row.sku)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm.row.shopName
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "white-space": "normal",
                            display: "flex",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { height: "18px" },
                            attrs: {
                              width: "18",
                              src: _vm.platform[_vm.row.platform],
                            },
                          }),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: _vm.row.shopName,
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ellipsis",
                                  staticStyle: {
                                    margin: "0 5px",
                                    "max-width": "150px",
                                    "line-height": "18px",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.row.shopName))]
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "line-height": "18px" } },
                            [
                              _vm._v(
                                "(" + _vm._s(_vm.country[_vm.row.region]) + ")"
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "flex-col",
              staticStyle: {
                padding: "10px 0",
                "justify-content": "space-between",
              },
            },
            _vm._l(_vm.public_tree.slice(0, 4), function (treeItem, i) {
              return _c(
                "div",
                {
                  key: "treeItem" + i,
                  staticClass: "flex-row flex-between",
                  staticStyle: { "line-height": "19px", color: "#333" },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-weight": "600",
                        "padding-left": "16px",
                      },
                    },
                    [_vm._v(_vm._s(treeItem.name))]
                  ),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        treeItem.symbol
                          ? _vm.myRateMoney(_vm.row[treeItem.code], _vm.row)
                          : treeItem.symbol || treeItem.rate
                          ? Number(_vm.row[treeItem.code]).toFixed(2)
                          : _vm.row[treeItem.code]
                      ) +
                        " " +
                        _vm._s(treeItem.rate ? "%" : "")
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            _vm._l(_vm.public_tree.slice(4), function (treeItem, j) {
              return _c(
                "el-collapse-item",
                { key: "treeItem" + (j + 4), attrs: { name: j } },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "flex-row" }, [
                      _c("i", {
                        staticClass: "el-icon-arrow-down arrowIcon",
                        staticStyle: { "margin-right": "4px" },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-weight": "600",
                            color: "#333",
                            "font-size": "12px",
                          },
                        },
                        [_vm._v(_vm._s(treeItem.name))]
                      ),
                    ]),
                  ]),
                  _vm._l(treeItem.children, function (child, index) {
                    return _c(
                      "div",
                      {
                        key: "child" + index,
                        staticClass: "flex-row flex-between",
                        staticStyle: {
                          "padding-left": "24px",
                          "padding-top": "4px",
                          "font-size": "12px",
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "max-width": "160px",
                              "line-height": "12px",
                            },
                          },
                          [_vm._v(_vm._s(child.name))]
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              child.symbol
                                ? _vm.myRateMoney(_vm.row[child.code], _vm.row)
                                : child.code === "refundCnt"
                                ? _vm.row[child.code]
                                : Number(_vm.row[child.code]).toFixed(2)
                            ) +
                              " " +
                              _vm._s(child.rate ? "%" : "")
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: { color: "#1377ff", cursor: "pointer" },
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [_vm._v(_vm._s(_vm.$t("title3509")))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }