
export default function friendWebsite () {
  let href=window.location.href;
  if(href.includes('hanriver')){
  // if(href.includes('localhost')){
    return {
      code:'hanriver',
      name:'韩江',
      serveTermId:8,
      ico: require('@/assets/chatPlus/hanriver.ico'),
      logo: require('@/assets/chatPlus/hanriver_logo.svg'),
    }
  }else {
    return {
      name:'心舰',
      serveTermId:4,
      logo: require('@/assets/logo/logo.svg'),
    }
  }
}
