<template>
  <el-popover
    width="340"
    :visible-arrow="false"
    trigger="click">
    <div style="padding:8px; max-height: 680px; overflow: hidden auto;" class="flex-col">
      <div class="flex-row" style="width: 100%; border-bottom: 1px solid #F6F7FB; padding-bottom: 20px;">
        <el-image
          v-if="row.imageUrl"
          :src="row.imageUrl"
          v-preview="row.imageUrl"
          style="width:32px;height:32px;min-width:32px;margin-right:10px"
        >
        </el-image>
        <div class="flex-col" style="width: 100%;height: 32px; justify-content: space-between;">
          <div class="flex-row" style="width: 100%; justify-content: space-between; align-items: center; margin-top: -2px">
            <el-tooltip class="item" effect="dark" :content="row.name" placement="top">
              <span style="max-width: 192px" class="ellipsis">{{ row.name }}</span>
            </el-tooltip>
            <span v-if="row.rating" style="color: #FD4C2B;">{{ Number(row.rating).toFixed(1) }}</span>
          </div>

          <span v-if="row.sku">
            <span style="color:#999;">{{row.sku || '-'}}</span>
            <i
              class="el-icon-document-copy"
              style=" margin-right: 10px; margin-left: 4px; cursor:pointer; color:#1377FF;"
              @click="navigatorCopy(row.sku)"
            ></i>
          </span>
          <div v-if="row.shopName" style="white-space: normal;display:flex;">
            <img style="height:18px" width="18" :src="platform[row.platform]" />
            <el-tooltip class="item" effect="dark" :content="row.shopName" placement="top">
              <span style="margin: 0 5px;max-width:150px; line-height: 18px;" class="ellipsis">{{row.shopName}}</span>
            </el-tooltip>
            <span style="line-height: 18px">({{ country[row.region] }})</span>
          </div>
        </div>
      </div>
      <div class="flex-col" style="padding: 10px 0; justify-content: space-between;">
        <div v-for="(treeItem, i) in public_tree.slice(0, 4)" :key="`treeItem${i}`" class="flex-row flex-between" style="line-height: 19px; color: #333">
          <span style="font-weight: 600; padding-left: 16px;">{{ treeItem.name }}</span>
          <span>{{ treeItem.symbol ? myRateMoney(row[treeItem.code], row) : ( treeItem.symbol || treeItem.rate ? Number(row[treeItem.code]).toFixed(2) : row[treeItem.code] ) }} {{ treeItem.rate ? '%' : '' }}</span>
        </div>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item  v-for="(treeItem, j) in public_tree.slice(4)" :key="`treeItem${j + 4}`" :name="j">
          <template slot="title">
            <div class="flex-row">
              <i class="el-icon-arrow-down arrowIcon" style="margin-right: 4px;"></i>
              <span style="font-weight: 600; color: #333; font-size: 12px">{{ treeItem.name }}</span>
            </div>
          </template>
          <div v-for="(child, index) in treeItem.children" :key="`child${index}`" class="flex-row flex-between" style="padding-left: 24px; padding-top: 4px; font-size: 12px">
            <span style="max-width: 160px; line-height: 12px;">{{ child.name }}</span>
            <span>{{ child.symbol ? myRateMoney(row[child.code], row) : child.code === 'refundCnt' ? row[child.code] : Number(row[child.code]).toFixed(2) }} {{ child.rate ? '%' : '' }}</span>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div style="color:#1377ff;cursor:pointer" slot="reference">{{$t('title3509')}}</div>
  </el-popover>
</template>
<script>
import numeral from 'numeral'
export default {
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    column: {
      type: Object,
      default: () => ({})
    },
    targetCol: {
      type: Array,
      default: () => []
    },
    initialState: {
      type: Object,
      default: () => ({})
    },
    isCutCurrency: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      hasProductCostPermi:this.checkPermi(['erp:sensitive:product-cost']),
      activeNames: [0, 1, 2, 3, 4],
      platform: {
        shopee: require('@/assets/images/shopee.png'),
        tiktok: require('@/assets/images/tiktok.png'),
        lazada: require('@/assets/images/lazada.png')
      },
      country: {
        BR: this.$t('title675'),
        TW: this.$t('台湾'),
        ID: this.$t('印尼'),
        MX: this.$t('title676'),
        MY: this.$t('马来西亚'),
        PH: this.$t('菲律宾'),
        SG: this.$t('新加坡'),
        TH: this.$t('泰国'),
        VN: this.$t('越南')
      },
    }
  },
  created() {},
  computed: {
    public_tree() {
      let expenses_children = [
        { code: 'productCost', name: this.$t('title715'), symbol: true },
        { code: 'packageFee', name: this.$t('title2629'), symbol: true },
        { code: 'adCost', name: this.$t('广告花费'), symbol: true }
      ]
      if (this.initialState.initialCost === 'true') {
        expenses_children.push({ code: 'initialCost', name: this.$t('title2293'), symbol: true })
      }
      if (this.initialState.finalCost === 'true') {
        expenses_children.push({ code: 'finalCost', name: this.$t('尾程费用'), symbol: true })
      }
      let arr = [
        { code: 'profit', name: this.$t('title5301'), symbol: true},
        { code: 'profitRate', name: this.$t('title5302'), rate: true},
        { code: 'saleVolume', name: this.$t('title11014')},
        { code: 'saleAmount', name: this.$t('title13003'), symbol: true},
        { name: this.$t('title2624'), children: [
          { code: 'saleAmount', name: this.$t('title24'), symbol: true }
        ]},
        { name: this.$t('title11099'), children: expenses_children },

        { name: this.$t('title3832'), children: [
          { code: 'refundCnt', name: this.$t('title33') },
          { code: 'refundAmount', name: this.$t('title4292'), symbol: true },
          { code: 'refundRate', name: this.$t('title29'), rate: true }
        ]},
        { name: this.$t('title655'), children: [
          { code: 'broadGmv', name: this.$t('title89'), symbol: true, },
          { code: 'ctr', name: this.$t('title677'), rate: true },
          { code: 'roas', name: 'ROAS' }
        ]},
        { name: this.$t('title11085'), children: [
          { code: 'testExpend', name: this.$t('title841'), symbol: true }
        ]},
        { name: this.$t('title1453') + this.$t('title4265'), children: [
          { code: 'tax', name: this.$t('title2294'), symbol: true },
          { code: 'otherAdjustFee', name: this.$t('title11235'), symbol: true }
        ]}
      ]
      if (this.platform_tree.children.length) {
        arr = arr.concat(this.platform_tree)
      }
      return arr
      return arr.filter(item=>this.hasProductCostPermi?true:(item.code||'').indexOf('profit')<0)
    },
    platform_tree() {
      const targetPlatform = this.targetCol.map(i => {
        return {
          code: i.code || i.prop,
          name: i.name || i.label,
          symbol: i.symbol,
          rate: i.rate
        }
      }).filter(item => !['costProfitRate', 'refundVolume', 'refundAmount', 'buyerPaidAmount', 'creditedAmount'].includes(item.code))
      // const arr = [
      //   { code: 'refundAmount', name: this.$t('title32'), symbol: true },
      //   { code: 'buyerPaidAmount', name: this.$t('买家实付金额'), symbol: true },
      //   { code: 'creditedAmount', name: this.$t('title11022'), symbol: true }
      // ]
      return {
        name: this.$t('title721'),
        children: targetPlatform
      }
    }
  },
  methods: {
    navigatorCopy(txt) {
      navigator.clipboard.writeText(txt).then(() => {
        this.$modal.msgSuccess(this.$t('title541'))
      }).catch(() => {
        this.$modal.msgError(this.$t('title1046'))
      })
    },
    myRateMoney(str, row) {
      if (str === undefined||str === null) {
        return "-";
      }
      if(this.isCutCurrency){
        const txt = numeral(str*1).format('0,0.00')
        return 'CNY '+ txt
      }else{
        const txt = numeral(str*row.exRate).format('0,0.00')
        return (row.currency||'')+' '+ txt
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-collapse {
  border-top: 1px solid #f6f7fb;
}
::v-deep .el-collapse-item {
  padding: 10px 0;
  border-bottom: 1px solid #f6f7fb;
}
::v-deep .el-collapse-item.is-active .el-icon-arrow-down{
  transform: rotate(180deg);
}
::v-deep .el-collapse-item__header {
  height: 20px;
  line-height: 20px;
  border: none;
  box-sizing: content-box;
}

::v-deep .el-collapse-item__arrow {
  display: none;
}
::v-deep .el-collapse-item .el-icon-arrow-down {
  line-height: 20px;
}
::v-deep .el-collapse-item__wrap {
  border-bottom: 0px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.settle{
  color:#4BBB82;
  background: #E6FEFA;
  border-radius:12px;
  line-height: 12px;
  padding: 2px 5px;
  &.none{
    color: #FD4C2B;
    background: #FDEDE8;
  }
}
</style>
