import Vue from 'vue'

import Element from 'element-ui'
import './assets/styles/element-variables.scss'
import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' // directive
import plugins from './plugins' // plugins
import './assets/icons' // icon
import './permission' // permission control
import './tongji' // 百度统计
import { formatAmount } from '@/utils'
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/infra/config";
import {
  parseTime,
  parseOrderTime,
  resetForm,
  handleTree,
  addBeginAndEndTime,
  divide,
  displayStr,
  displayNumber,
  strSymbolReplace,
  getRateMoney,
  totalGetRateMoney,
  summaryGetRateMoney,
  renderSaleDay,
  tableShowTooltip,
  clearMouseSelect,
  displayRate,
  getCurrency,
  getCurrencySymbol,
  forChatplus,
  mouseScroll
} from "@/utils/ruoyi";
mouseScroll()
import friendWebsite from "@/utils/friendWebsite";
import Pagination from "@/components/Pagination";
// 自定义表格工具扩展
import RightToolbar from "@/components/RightToolbar"
import MyDialog from '@/components/MyDialog'
import MyVScrollTable from '@/components/MyVScrollTable'

import TableControl from '@/components/TableControl'
// 代码高亮插件
// import hljs from 'highlight.js'
// import 'highlight.js/styles/github-gist.css'
import {DICT_TYPE, getDictDataLabel, getDictDatas, getDictDatas2} from "@/utils/dict";
import {checkPermi} from "@/utils/permission";
import vScroll from "@/components/vScroll"
import vColumn from "@/components/vScroll/vColumn"

import horizontalScroll from 'el-table-horizontal-scroll';
Vue.use(horizontalScroll);

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.parseOrderTime = parseOrderTime
Vue.prototype.displayStr = displayStr
Vue.prototype.displayNumber = displayNumber
Vue.prototype.strSymbolReplace = strSymbolReplace
Vue.prototype.getRateMoney = getRateMoney
Vue.prototype.totalGetRateMoney = totalGetRateMoney
Vue.prototype.summaryGetRateMoney = summaryGetRateMoney
Vue.prototype.displayRate = displayRate
Vue.prototype.resetForm = resetForm
Vue.prototype.getDictDatas = getDictDatas
Vue.prototype.getDictDatas2 = getDictDatas2
Vue.prototype.getDictDataLabel = getDictDataLabel
Vue.prototype.DICT_TYPE = DICT_TYPE
Vue.prototype.handleTree = handleTree
Vue.prototype.addBeginAndEndTime = addBeginAndEndTime
Vue.prototype.divide = divide
Vue.prototype.checkPermi = checkPermi
Vue.prototype.tableShowTooltip = tableShowTooltip
Vue.prototype.renderSaleDay = renderSaleDay
Vue.prototype.clearMouseSelect = clearMouseSelect
Vue.prototype.formatAmount = formatAmount

Vue.prototype.myCurrency = getCurrency
Vue.prototype.mySymbol = getCurrencySymbol
Vue.prototype.forChatplus = forChatplus
Vue.prototype.friendWebsite = friendWebsite


// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('DocAlert', DocAlert)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('MyDialog', MyDialog)
Vue.component('MyVScrollTable', MyVScrollTable)
Vue.component('vScroll', vScroll)
Vue.component('vColumn', vColumn)
Vue.component('TableControl', TableControl)

// 字典标签组件
import DictTag from '@/components/DictTag'
import DocAlert from '@/components/DocAlert'
// 头部标签插件
import VueMeta from 'vue-meta'
// import VueTypedJs from 'vue-typed-js'
// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'


// Vue.use(mavonEditor)
// Vue.use(VueTypedJs)
Vue.use(directive)
Vue.use(plugins)
Vue.use(VueMeta)
// Vue.use(hljs.vuePlugin);

// bpmnProcessDesigner 需要引入
import MyPD from "@/components/bpmnProcessDesigner/package/index.js";
Vue.use(MyPD);
import "@/components/bpmnProcessDesigner/package/theme/index.scss";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";

// Form Generator 组件需要使用到 tinymce
// import Tinymce from '@/components/tinymce/index.vue'
// Vue.component('tinymce', Tinymce)

import '@/icons'
import request from "@/utils/request" // 实现 form generator 使用自己定义的 axios request 对象
Vue.prototype.$axios = request
import '@/styles/index.scss'

import i18n from './i18n'

// 默认点击背景不关闭弹窗
import ElementUI from 'element-ui'
ElementUI.Dialog.props.closeOnClickModal.default = false

let tabDelay = null
// if (ElementUI.Tabs) {
//   const originalHandleTabClick = ElementUI.Tabs.methods.handleTabClick;
//   console.log(originalHandleTabClick)
//   ElementUI.Tabs.methods.handleTabClick = function(...args) {
//     if (tabDelay) clearTimeout(tabDelay)
//     tabDelay = setTimeout(() => {
//       console.log('delay600')
//       originalHandleTabClick.apply(this, args);
//     }, 600)
//   }
// }

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: localStorage.getItem("size") || "medium", // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
});

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
