<template>
  <div class="control-row flex-row" ref="tableControlBox" :style="{'height': height}">
    <div style="color: #999;margin-left: 10px">
      <span v-if="total">{{`${$t('title11002')}${total}${$t('title11003')}`}}</span>
      <slot name="tips">
        <span v-if="tips" style="margin-left: 20px">{{ tips }}</span>
      </slot>
      <slot></slot>
    </div>
    <div class="flex-row align-center">
      <slot name="checkCorner">
        <div class="flex-row align-center">
          <el-checkbox v-if="showCheckL" v-model="computedCheckL">{{checkLStr || $t('title11004')}}</el-checkbox>
          <el-checkbox v-if="showCheckR" v-model="computedCheckR">{{checkRStr || $t('title11005')}}</el-checkbox>
        </div>
        <el-tooltip class="item" effect="dark" placement="top" v-if="showCutCurrency && isClickCurrency">
          <div slot="content">
            <div class="flex-col" slot="content">
              <span>温馨提示：</span>
              <span>仅支持单地区选择时切换币种，不支持多地区切换。</span>
              <span>切换币种时，根据【设置】-【汇率设置】中的今日执行汇率进行计算。</span>
              <span>无论是选择区间还是某一天的统计数据，都会以今日执行汇率为准。</span>
              <span>需要注意的是，系统同步订单执行的是当时同步时间的汇率。</span>
              <span>因此，在汇率切换后可能存在汇率偏差，不应仅凭此数据作为唯一比较标准。</span>
            </div>
          </div>
          <el-button :disabled="isClickCurrency" plain style="color: #1377FF;padding: 0 0 0 15px;" :class="['my-cut-currency',isCutCurrency&&'rotate']" size="small" @click="btnClick('currency')">
            <i></i>
            <span>{{ $t('title1265') }}</span>
          </el-button>
        </el-tooltip>
        <el-button v-if="showCutCurrency && !isClickCurrency" :disabled="isClickCurrency" plain style="color: #1377FF;padding: 0 0 0 15px;" :class="['my-cut-currency',isCutCurrency&&'rotate']" size="small" @click="btnClick('currency')">
          <i></i>
          <span>{{ $t('title1265') }}</span>
        </el-button>
        <div v-if="showCheckL || showCheckR ||showCutCurrency" class="verticalLine"></div>
      </slot>
      <div class="flex-row align-center">
        <missionCenter class="btn" v-if="!isHideTask" :isHideTask="isHideTask" :isInclude="isInclude" :isImport="isImport" ref="mission"></missionCenter>
        <div class="costom-col-btn flex-row" v-if="showCostomCol" style="align-items: center; justify-content: space-between">
          <span class="colBtn" style="cursor: pointer;" @click="createCol">{{ $t('title11198') }}</span>
          <el-tooltip class="item" effect="dark" :visible-arrow="false" :content="$t('title11209')" placement="top">
            <el-popover
              width="200"
              :offset="-78"
              placement="bottom"
              :visible-arrow="false"
              popper-class="col-popover"
              trigger="click">
              <div class="col_edit_view flex-col">
                <template v-if="costomColList.length">
                  <div v-for="col in costomColList" :key="col.id" class="flex-row col-row" style="justify-content: space-between; line-height: 20px; align-items: center">
                    <div class="costom_name">{{col.name}}</div>
                    <span>
                      <el-tooltip class="item" effect="dark" :visible-arrow="false" :content="$t('title1593')" placement="top">
                        <el-button
                          style="margin-left: 10px"
                          type="text"
                          size="mini"
                          @click.stop="editCol(col)">
                          <i class="el-icon-edit"></i>
                        </el-button>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" :visible-arrow="false" :content="$t('title256')" placement="top">
                        <el-button
                          type="text"
                          size="mini"
                          style="margin-left: 10px"
                          @click.stop="removeCol(col)">
                          <i class="el-icon-delete"></i>
                        </el-button>
                      </el-tooltip>
                    </span>
                  </div>
                </template>
                <div v-else style="width: 100%; text-align: center">{{ $t('title712') }}</div>
              </div>
              <span slot="reference">
                <img :src="colEditBtn" style="width: 16px; height: 16px; margin-bottom: -3px; cursor: pointer" @mouseenter="colEditBtn = colEditHoverImg" @mouseleave="colEditBtn = colEditImg">
              </span>
            </el-popover>
          </el-tooltip>
        </div>
        <slot name="region"></slot>
        <el-tooltip class="item" effect="dark" :content="$t('title5')" placement="top-start">
          <div class="btn" v-if="!isHideHelp" @click="btnClick('help')">
            <img src="@/assets/images/control-help.png" />
          </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('title1897') + $t('title2379')" placement="top">
          <div class="btn" v-if="!isHideRefresh" @click="btnClick('refresh')">
            <img src="@/assets/images/control-refresh.png" />
          </div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('title430') + $t('title10130') + $t('title10515')" placement="top">
          <div class="btn" v-if="!isHideSetting" @click="btnClick('setting')">
            <img src="@/assets/images/control-setting.png" />
          </div>
        </el-tooltip>
        <el-tooltip v-exportAuthButton class="item" effect="dark" :content="$t('title192') + $t('title2379')" placement="top-end">
          <div class="btn" v-if="!isHideDownload" @click="btnClick('download')">
            <img src="@/assets/images/control-download.png" />
          </div>
        </el-tooltip>
      </div>
    </div>
    <sort-panel v-if="sortPanelShow && !sortType" v-model="sortPanelShow" :sortItems="sortItems" @after-save="afterSave" />
    <sort-tree-panel v-if="sortTreePanelShow && sortType === 'tree'" v-model="sortTreePanelShow" :sortItems="sortItems" @after-save="afterSave" />
    <sortTreePanelCopy v-if="sortTreePanelShow && sortType === 'complex'" v-model="sortTreePanelShow" :sortItems="sortItems" @after-save="afterSave" />
    <costom-column-panel v-if="costColPanelShow" v-model="costColPanelShow" :sortItems="sortItems" :api='apiUrl' :id="colId" :colInfo="colInfo" @after-save="afterSaveCol" />
  </div>
</template>
<script>
import sortPanel from '@/components/TableControl/sortPanel'
import sortTreePanel from '@/components/TableControl/sortTreePanel'
import sortTreePanelCopy from '@/components/TableControl/sortTreePanelCopy'
import missionCenter from '@/views/crm/components/missionCenter/index.vue'
import costomColumnPanel from '@/components/costomColumnPanel/index.vue'
import { debounce } from 'throttle-debounce'
export default {
  components: {
    sortPanel,
    sortTreePanel,
    sortTreePanelCopy,
    missionCenter,
    costomColumnPanel
  },
  props: {
    height: {
      type: String,
      default: '40px'
    },
    localLabel:String,//storage名称，保存排序
    total: [String, Number],
    tips: String,
    showCheckL: {
      type: Boolean,
      default: false
    },
    dataItems:{
      type:Array,
      default:()=>[]
    },
    isInclude:{
      type:Boolean,
      default:true
    },
    isImport:{
      type:Boolean,
      default:true
    },
    isHideTask:{
      type:Boolean,
      default:true
    },
    isHideHelp:{
      type:Boolean,
      default:false
    },
    isHideRefresh:{
      type:Boolean,
      default:false
    },
    isHideSetting:{
      type:Boolean,
      default:false
    },
    isHideDownload:{
      type:Boolean,
      default:false
    },
    checkL: {
      type: Boolean,
      default: false
    },
    checkLStr: String,
    showCutCurrency: {
      type: Boolean,
      default: false
    },
    isClickCurrency:{
      type: Boolean,
      default: true
    },
    isCutCurrency: {
      type: Boolean,
      default: false
    },
    showCheckR: {
      type: Boolean,
      default: false
    },
    checkR: {
      type: Boolean,
      default: false
    },
    checkRStr: String,
    sortType: null,
    showCostomCol: {
      type: Boolean,
      default: false
    },
    apiUrl: String,
    costomColList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      div_hover: false,
      colEditBtn: require('@/assets/images/costomBtn.png'),
      colEditImg: require('@/assets/images/costomBtn.png'),
      colEditHoverImg: require('@/assets/images/costomBtnHover.png'),
      sortPanelShow: false,
      sortTreePanelShow: false,
      costColPanelShow: false,
      colId: null,
      colInfo: {}
    }
  },
  mounted() {
    const container = this.$refs.tableControlBox
    const resizeObserver = new ResizeObserver(entries => {
      const dom = this.$refs.mission
      this.$store.state.missionCenter = dom
    })
    resizeObserver.observe(container)
  },
  beforeDestroy() {
    const container = this.$refs.tableControlBox
    const resizeObserver = new ResizeObserver(entries => {})
    resizeObserver.unobserve(container)
  },
  computed: {
    computedCheckL: {
      get: function() {
        return this.checkL
      },
      set: function(val) {
        this.$emit('update:checkL', val);
        this.$emit('refresh');
      }
    },
    computedCheckR: {
      get: function() {
        return this.checkR
      },
      set: function(val) {
        this.$emit('update:checkR', val);
        this.$emit('refresh');
      }
    },
    sortItems() {
      const arr = this.dataItems.map(i => {
        let children = []
        let target = {}
        if (i.children && i.children.length) {
          children = i.children.map(child => {
            return {
              ...child,
              checked: child.sort !== 99
            }
          })
        }
        target = {
          ...i,
          checked: i.sort !== 99
        }
        if (children.length) target.children = children
        return target
      })
      return arr
    }
  },
  methods: {
    btnClick: debounce(300, function(type) {
      if (type === 'setting') {
        this.sortPanelShow = true
        this.sortTreePanelShow = true
      } else {
        this.$emit(type)
      }
    }),
    createCol() {
      this.colInfo = {}
      this.colId = null
      this.costColPanelShow = true
    },
    editCol(row) {
      this.colInfo = row
      this.colId = row.id
      this.costColPanelShow = true
    },
    removeCol(row) {
      this.$emit('removeCol', row)
    },
    afterSaveCol(code, position) {
      this.$emit('afterSaveCol', code, position)
    },
    afterSave(arr){
      this.$emit('afterSave',arr)
    }
  }
}
</script>
<style lang="scss" scoped>
.control-row {
  width: 100%;
  align-items: center;
  justify-content: space-between
}
.verticalLine {
  height: 16px;
  width: 1px;
  background: #e4e4e4;
  margin: 0 20px;
}
.align-center {
  align-items: center
}
.btns-row {
  align-items: center;
  justify-content: flex-end
}
.btn {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}
.btn:first-child {
  margin-left: 0px;
}
.btn:hover {
  background: #f2f2f2;
}
::v-deep .el-checkbox__input {
  margin-bottom: 1px
}
::v-deep .el-checkbox__label {
  font-size: 12px;
  color: #333
}
.myCurrency1 {
  height: 34px;
}
.costom-col-btn {
  width: 128px;
  height: 36px;
  border: 1px solid #d3d3d3;
  background: #fff;
  border-radius: 4px;
  padding: 0 10px 0 16px;
  color: #666;
}
// .col_edit_view {}
.costom_name {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.colBtn:hover {
  color: #1377FF
}
.col-row {
  height: 32px;
  padding: 0 12px;
}
.col-row:hover {
  background: #F5F7FA;
}
</style>

<style>
.col-popover {
  padding: 12px 0
}
</style>
